import React from "react";
import PropTypes from "prop-types";

const Modal = ({ children, open }) => {
  return (
    <div
      className={
        open
          ? "z-20 fixed top-0 bottom-0 inset-x-0 px-4 py-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center"
          : "hidden"
      }
    >
      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      <div className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full sm:p-6">
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Modal;
